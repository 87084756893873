<template>
  
    <navbar></navbar>
    <router-view></router-view>
    <my-footer></my-footer>

</template>

<script>

import Navbar from '../components/navbar/navbar.vue'
import myFooter from '../components/footer/myfooter.vue'

export default {
    name: 'Armada',
    components: {
        Navbar,
        myFooter
    }
}
</script>

<style>

</style>